
import './App.css';
import { CompetitionContainer } from './views/competitionView/CompetitionContainer';
import { StageContainer } from './views/competitionView/StageContainer';
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid';
import './views/competitionView/DataGrid.scss';
import kisa3 from'./kisa3.png';
import kisa3_1 from'./kisa3_s1.png';
import kisa3_2 from'./kisa3_s2.png';
import kisa3_3 from'./kisa3_s3.png';
import kisa3_4 from'./kisa3_s4.png';


const columns: GridColDef[] = [
  { field: 'col1', flex: 2, renderHeader: () => (
    <strong>
      {'Ampuja'}
    </strong>
  ) },
  { field: 'col2', flex: 1, renderHeader: () => (
    <strong>
      {'Pisteet'}
    </strong>
  )  },
  { field: 'col3', flex: 1, renderHeader: () => (
    <strong>
      {'Aika'}
    </strong>
  )  },
  { field: 'col4', flex: 1, renderHeader: () => (
    <strong>
      {'Osumakerroin'}
    </strong>
  )  },
  { field: 'col5', flex: 1, renderHeader: () => (
    <strong>
      {'%'}
    </strong>
  )  },
];





const KokKilpailuSyys: GridRowsProp = [
  { id: 1, col1: 'Jesse', col2: 358.3, col5: "100%" },
  { id: 2, col1: 'Riku', col2: 332.47,    col5: "92.79%" },
  { id: 3, col1: 'Heijari', col2: 279.09, col5: "77.89%" },
  { id: 4, col1: 'Kai', col2: 227.83,   col5: "63.58%" },
  { id: 5, col1: 'Haapa', col2: 219.82,   col5: "61.34%" },
  { id: 6, col1: 'Jimmy', col2: 219.70,  col5: "61.31%" },
  { id: 7, col1: 'Jeesus', col2: 104.32,  col5: "29.11%" },
];





const KokKilpailu: GridRowsProp = [
  { id: 1, col1: 'Jesse', col2: 364, col3: 93.99, col4: 3.87, col5: "100%" },
  { id: 2, col1: 'Felix', col2: 383, col3: 101.31, col4: 3.78 , col5: "97.62%" },
  { id: 3, col1: 'Heijari', col2: 319, col3: 130.81, col4: 2.44, col5: "62.97%" },
  { id: 4, col1: 'Haapa', col2: 247, col3: 109.2, col4: 2.26, col5: "58.41%" },
  { id: 5, col1: 'Jimmy', col2: 249, col3: 123.97, col4: 2.01, col5: "51.86%" },
  { id: 6, col1: 'Jeesus', col2: 248, col3: 253, col4: 0.98, col5: "25.31%" },
];


const Stage1: GridRowsProp = [
  {id:	1	 ,col1:"	Felix	",col2:	113	,col3:	28.25	,col4:	4.00	,col5:"	100.00 %	"},
  {id:	2	 ,col1:"	Jesse	",col2:	95	,col3:	25.67	,col4:	3.70	,col5:"	92.52 %	"},
  {id:	3	 ,col1:"	Jimmy	",col2:	71	,col3:	28.63	,col4:	2.48	,col5:"	62.00 %	"},
  {id:	4	 ,col1:"	Haapa	",col2:	68	,col3:	29.62	,col4:	2.30	,col5:"	57.39 %	"},
  {id:	5	 ,col1:"	Heijari	",col2:	57	,col3:	37.94	,col4:	1.50	,col5:"	37.56 %	"},
  {id:	6	 ,col1:"	Jeesus	",col2:	46	,col3:	71.15	,col4:	0.65	,col5:"	16.16 %	"},
  
];

const Stage2: GridRowsProp = [
  {id:	1	 ,col1:"	Heijari	",col2:	115	,col3:	30.67	,col4:	3.75	,col5:"	100.00 %	"},
  {id:	2	 ,col1:"	Jimmy	",col2:	93	,col3:	25.75	,col4:	3.61	,col5:"	96.32 %	"},
  {id:	3	 ,col1:"	Jesse	",col2:	101	,col3:	29.08	,col4:	3.47	,col5:"	92.63 %	"},
  {id:	4	 ,col1:"	Haapa	",col2:	80	,col3:	23.05	,col4:	3.47	,col5:"	92.56 %	"},
  {id:	5	 ,col1:"	Felix	",col2:	99	,col3:	31.51	,col4:	3.14	,col5:"	83.79 %	"},
  {id:	6	 ,col1:"	Jeesus	",col2:	109	,col3:	85.85	,col4:	1.27	,col5:"	33.86 %	"},
  
];

const Stage3: GridRowsProp = [
  {id:	1	 ,col1:"	Jesse	",col2:	168	,col3:	39.24	,col4:	4.28	,col5:"	100.00 %	"},
  {id:	2	 ,col1:"	Felix	",col2:	171	,col3:	41.55	,col4:	4.12	,col5:"	96.13 %	"},
  {id:	3	 ,col1:"	Heijari	",col2:	147	,col3:	62.2	,col4:	2.36	,col5:"	55.20 %	"},
  {id:	4	 ,col1:"	Haapa	",col2:	99	,col3:	56.53	,col4:	1.75	,col5:"	40.90 %	"},
  {id:	5	 ,col1:"	Jimmy	",col2:	85	,col3:	69.59	,col4:	1.22	,col5:"	28.53 %	"},
  {id:	6	 ,col1:"	Jeesus	",col2:	93	,col3:	96	,col4:	0.97	,col5:"	22.63 %	"},
  
  
];

function App() {
  return (
    <div className="App">
    <header className = "App-header">






    <CompetitionContainer
 title='Luostarinpoikain sarjakisa 20.11' 
 results = {<div> 
<img style={{display:"block", margin:"auto"}} src={kisa3} alt="Results"/>
<StageContainer title='Rasti 1, Pistooli' results={<img style={{display:"block", margin:"auto"}} src={kisa3_1} alt="Results"/>}/>
<StageContainer title='Rasti 2, Minirifle' results={<img style={{display:"block", margin:"auto"}} src={kisa3_2} alt="Results"/>}/>
<StageContainer title='Rasti 3, Pistooli' results={<img style={{display:"block", margin:"auto"}} src={kisa3_3} alt="Results"/>}/>
<StageContainer title='Rasti 4, Kivääri' results={<img style={{display:"block", margin:"auto"}} src={kisa3_4} alt="Results"/>}/>

 </div>}

></CompetitionContainer>













<CompetitionContainer
 title='Luostarinpoikain syyskisa 22.10, 10-15' 
 results = {<div>
<DataGrid rows={KokKilpailuSyys} columns={columns} autoHeight/>



 </div>}

></CompetitionContainer>


<CompetitionContainer
 title='Tulokset, luostarinpoikain kuukausi cup 16.10' 

 results={<div>
   <DataGrid rows={KokKilpailu} columns={columns} autoHeight/>
  <StageContainer title='Rasti 1, Pistooli' results={<DataGrid rows={Stage1} columns={columns} autoHeight/>}/>
  <StageContainer title='Rasti 2, Kivääri' results={<DataGrid rows={Stage2} columns={columns} autoHeight/>}/>
  <StageContainer title='Rasti 3, Haulikko & Pistooli' results={<DataGrid rows={Stage3} columns={columns} autoHeight/>}/>
  </div>
  }

>

</CompetitionContainer>
 
      </header>


   
 

    </div>
  );
}

export default App;
