import { ReactNode, FC, useState } from 'react';
import { ReactComponent as Chevron } from './../../assets/vector/chevron.svg';
import './Stage.scss';

export const StageContainer: FC<{
  title: string;
  value?: number;
  results?: ReactNode | undefined;
}> = ({ title, results}) => {
  const [isOpen, isOpenSet] = useState<boolean>(false);
  const toggle = () => isOpenSet((prev) => !prev);
  return (
    <div className={`StageContainer ${isOpen ? ' StageContainer--open' : ''}`}>
      <div className="StageContainer__wrapper">
        <h2 className="StageContainer__title" onClick={toggle}>
          <span className="StageContainer__title-text">{title}</span>
          <span className="StageContainer__title-icon">
            <Chevron />
          </span>
        </h2>
        <div className="StageContainer__results">{results}</div>
      </div>
    </div>
  );
};
