import { ReactNode, FC, useState } from 'react';
import { ReactComponent as Chevron } from './../../assets/vector/chevron.svg';
import './Competition.scss';

export const CompetitionContainer: FC<{
  title: string;
  value?: number;
  stages?: ReactNode | undefined;
  results?: ReactNode | undefined;
}> = ({ title, results, stages }) => {
  const [isOpen, isOpenSet] = useState<boolean>(false);
  const toggle = () => isOpenSet((prev) => !prev);
  return (
    <div className={`CompetitionContainer ${isOpen ? ' CompetitionContainer--open' : ''}`}>
      <div className="CompetitionContainer__wrapper">
        <h2 className="CompetitionContainer__title" onClick={toggle}>
          <span className="CompetitionContainer__title-text">{title}</span>
          <span className="CompetitionContainer__title-icon">
            <Chevron />
          </span>
        </h2>
        <div className="CompetitionContainer__stages">{stages}</div>
        <div className="CompetitionContainer__results">{results}</div>

      </div>
    </div>
  );
};
